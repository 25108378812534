/**
 * Used for checking if the current logged in user has permission
 */
import { isUserLoggedIn, useUser } from '@modules/user';
import type { CurrentMemberFragment, PermissionEnum } from 'generated/graphql';
import type { Maybe } from '@modules/root';
import type { User } from '~/server/types';

const collectPermissions = (
    teamMembers: CurrentMemberFragment['TeamMembers'],
    professionalIdentifier: string | number
): PermissionEnum[] => {
    const team = teamMembers
        .filter((team) => {
            if (typeof professionalIdentifier === 'string') {
                return team.Professional.URLSegment === professionalIdentifier;
            } else {
                return team.Professional.ID === professionalIdentifier;
            }
        })
        .pop();

    if (!team) {
        return [];
    }

    const professionalPermissions = team.Professional.Permissions ?? [];

    return [...team.Permissions, ...professionalPermissions];
};

/**
 * you can check a user permission for a given Professional or object that has a Professional
 * When professionalIdentifier is undefined it will look for root level Member permissions
 * @param user
 * @param permissions
 * @param professionalIdentifier
 */
export const getPermissions = (
    user: User,
    permissions: PermissionEnum | PermissionEnum[],
    professionalIdentifier?:
        | Maybe<string | number>
        | { Professional?: Maybe<{ ID: number }> }
) => {
    if (!isUserLoggedIn(user)) {
        return false;
    }

    const { Permissions, TeamMembers } = user;

    const permissionsToCheck = Array.isArray(permissions)
        ? permissions
        : [permissions];

    const membersPermissions = [...Permissions];
    if (professionalIdentifier) {
        /**
         * collect permissions from the team member
         */
        if (typeof professionalIdentifier === 'object') {
            if (professionalIdentifier.Professional?.ID) {
                membersPermissions.push(
                    ...collectPermissions(
                        TeamMembers,
                        professionalIdentifier.Professional.ID
                    )
                );
            }
        } else {
            membersPermissions.push(
                ...collectPermissions(TeamMembers, professionalIdentifier)
            );
        }
    }

    return permissionsToCheck.every((permission) =>
        membersPermissions.includes(permission)
    );
};
/**
 * @deprecated use getPermissions in the loader
 * @param permissions
 * @param professionalIdentifier
 */
export const usePermissions = (
    permissions: PermissionEnum | PermissionEnum[],
    professionalIdentifier?: Maybe<string | number>
): boolean => {
    const user = useUser();
    return getPermissions(user, permissions, professionalIdentifier);
};
